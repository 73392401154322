<template>
  <TransitionGroup
    name="list"
    tag="div"
    class="fixed bottom-2 left-1/2 z-50 w-fit -translate-x-1/2 transform lg:left-auto lg:right-4 lg:translate-x-0"
  >
    <Toast v-for="toast in toasts" :key="toast.id" :="{ toast }" />
  </TransitionGroup>
</template>

<script setup lang="ts">
// data
const { toasts } = useToast()
</script>
