<template>
  <output
    role="status"
    :class="[
      'group relative mb-3 flex min-h-12 max-w-sm items-center rounded-lg p-2 shadow-lg',
      `Toast--${toast.type}`
    ]"
  >
    <TwSpinner v-if="toast.type === 'loading'" class="h-5 w-5 text-gray-500" />
    <Icon
      v-else
      :name="iconMap[toast.type]"
      :class="['h-5 w-5', `Toast__icon--${toast.type}`]"
    />
    <div class="mx-4 flex max-w-sm flex-col">
      <span class="truncate text-sm text-gray-600">
        {{ toast.title }}
      </span>
      <p class="break-words text-xs text-gray-400">
        {{ toast.description }}
      </p>
    </div>
    <button
      :class="['flex transition-all', `Toast__close--${toast.type}`]"
      type="button"
      @click="removeToast(toast)"
    >
      <Icon
        name="x"
        :class="['h-5 w-5', `Toast__icon--${toast.type}`]"
        aria-hidden="true"
      />
    </button>
  </output>
</template>

<script setup lang="ts">
// defines
const props = defineProps<{ toast: Toast }>()

// data
const { removeToast } = useToast()
const iconMap = {
  error: 'x-circle-fill',
  success: 'check-circle',
  info: 'info-circle',
  warning: 'exclamation',
  loading: ''
}

// watchers
watchEffect(() => {
  const timeout = setTimeout(() => {
    removeToast(props.toast)
  }, props.toast.timeout * 1000)
  return () => clearTimeout(timeout)
})
</script>
